import { Paper, Card, CardHeader, CardContent, FormControlLabel, Box } from '@material-ui/core';

import background from 'assets/login_background.png';
import { ITheme } from 'styles';

import styled from 'styled-components/macro';

export const StyledLoginForm = styled.form`
  width: 100%; /* Fix IE 11 issue. */
  margin-top: ${p => p.theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export default styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: url("${background}");
    background-size: cover;
    filter: brightness(2);
    z-index: -2;
  }
  &::after {
    background: #FCFCFC;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    z-index: -1;
    opacity: .6;
  }
`;

export const LoginBox = styled(Box)`
  @media screen and (max-width: 477px) {
    height: 100%;
  }
`;

export const LoginCard = styled(Card)`
  width: 345px;

  overflow: visible;

  /* @media screen and (max-height: 645px) {
    margin-top: 30%;
  }
  /* @media screen and (max-width: 599px) {
    margin-top: 30%;
  } */

  @media screen and (max-width: 350px) {
    width: 300px;
  }

  @media screen and (max-width: 477px) {
    background: #ef6915;
    width: 100vw;
    background-color: white;
    height: 100%;
  }
`;

export const LoginCardHeader = styled(CardHeader)`
  background: #ef6915;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .MuiCardHeader-avatar {
    position: absolute;

    .MuiAvatar-root {
      height: 150px;
      width: 150px;
    }
  }
  .MuiCardHeader-title {
    display: none;
  }

  @media screen and (max-width: 477px) {
    padding: 80px;

    .MuiCardHeader-title {
      color: #fff;
      font-weight: bold;
      display: flex;
    }
    .MuiCardHeader-avatar {
      position: static;
      .MuiAvatar-root {
        margin-left: 20px;
      }
    }
  }

  @media screen and (max-height: 700px) {
    padding: 40px;
  }

  @media screen and (max-height: 568px) {
    padding: 8px;
  }
`;

export const LoginCardContent = styled(CardContent)`
  margin-top: 6%;
  @media screen and (max-width: 477px) {
    background: #fff;
    border-radius: 15px 15px 0 0;
  }
`;

export const LoginFooterContainer = styled.footer`
  background: transparent linear-gradient(180deg, #f59019 0%, #f26900 100%);
  color: white;

  width: 100%;
  height: 4%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 477px) {
    display: none;
  }
`;

export const StyledLoginHeader = styled.div`
  background: transparent linear-gradient(180deg, #f59019 0%, #f26900 100%);
  color: white;

  display: flex;
  justify-content: center;

  height: 3.1%;
  width: 100%;

  @media screen and (max-width: 477px) {
    display: none;
  }
`;

export const StyledLoginHeaderCardContainer = styled.div`
  background: #ef6915;
  width: 15%;

  display: flex;
  justify-content: center;
  position: relative;

  ${p => (p.theme as ITheme).breakpoints.down('md')} {
    width: 30%;
  }

  ${p => (p.theme as ITheme).breakpoints.down('sm')} {
    width: 60%;
  }
`;

export const StyledLoginHeaderCardTitle = styled(Paper)`
  padding: ${p => p.theme.spacing(1)}px;
  border-radius: 0 0 4px 4px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
`;

export const StyledLoginCheckbox = styled(FormControlLabel)`
  margin-top: 1%;
  margin-bottom: 3%;

  .MuiFormControlLabel-label {
    ${p => p.theme.typography.caption};
  }
`;
