import { gql } from 'apollo-boost';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    employee: FuncionarioType!
  }
`;

export const resolvers = {};
