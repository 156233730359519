import { gql } from 'apollo-boost';

import { CustomJsonWebToken } from 'types/graphql';

export const loginUserMutation = gql`
  mutation loginUser($username: String!, $password: String!) {
    obterToken(username: $username, password: $password) {
      token
      funcionario {
        id
        nome
        email
        categoria
        tipoContrato
      }
    }
  }
`;

export interface LoginUserMutationType {
  obterToken: CustomJsonWebToken;
}
