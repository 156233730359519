import ApolloClient from 'apollo-boost';
import { format } from 'date-fns';

import { typeDefs, resolvers } from './resolvers';

function getInStorage(key: string) {
  return localStorage.getItem(key) || sessionStorage.getItem(key);
}

function getToken() {
  const tokenValue =
    getInStorage('should_remember') === String(true)
      ? getInStorage('token')
      : getInStorage('logged_time') === format(new Date(), 'MM/dd/yyyy') && getInStorage('token');
  if (tokenValue === 'undefined') return null;
  return tokenValue;
}

export const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_GRAPHQL,
  request: operation => {
    const token = getToken();

    operation.setContext({
      headers: {
        authorization: token ? `JWT ${token}` : '',
      },
    });
  },
  clientState: {
    defaults: {
      isLoggedIn: !!getToken(),
      employee: JSON.parse(getInStorage('employee')!),
    },
    resolvers,
    typeDefs,
  },
});

Object.assign(client.defaultOptions, { watchQuery: { fetchPolicy: 'cache-and-network' } });
